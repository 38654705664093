<template>
<v-chart class="chart" :option="option" />
</template>

<script>
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  PieChart
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, {
  THEME_KEY
} from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: "AverageDonut",
  components: {
    VChart
  },
  props: ['data', 'palette'],
  provide: {
    [THEME_KEY]: "light"
  },
  computed: {
    option: function () {
      let data = [];
      let legendData = [];
      this.data.map(d => {
        data.push({
          name: d.name,
          value: d.y
        });
        legendData.push(d.name);
      })
      return {
        title: {
          text: "OKR整体平均完成度",
          left: "left",
          show: true
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          show: false
        },
        legend: {
          orient: "vertical",
          left: "right",
          itemWidth: "100",
          itemHeight: "50",
          data: legendData,
          itemStyle: {
            color: "white",
            borderColor: "grey",
            borderWidth: "1"
          },
          show: false
        },
        avoidLabelOverlap: false,
        series: [{
          name: "",
          type: "pie",
          radius: [70, 110],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            fontSize: '36px',
            fontWeight: 'bold'
          },
          center: ["50%", "60%"],
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }],
        color: this.palette,
      }
    }
  },
  data() {
    return {

    };
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
